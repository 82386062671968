import "./src/styles/global.css";

import React from "react";
import AnimatedCursor from "react-animated-cursor";
import Provider from "./src/components/ui/Provider";

export const onInitialClientRender = () => {
  const theme = localStorage.getItem("darkMode") || "false";
  document.documentElement.classList.toggle("dark", theme === "true");
};

export const wrapRootElement = ({ element }) => {
  if (typeof window !== "undefined") {
    return (
      <>
        <AnimatedCursor color="37, 99, 235" />
        <Provider>{element}</Provider>
      </>
    );
  }

  return element;
};
