import React, { useState, useEffect, createContext } from "react";

import { isBrowser } from "../../lib/utils";

export const ProviderContext = createContext({
  isDarkMode: false,
  setIsDarkMode: (isDarkMode: boolean) => {},
});

export default function Provider(props: { children: React.ReactNode }) {
  const [isDarkMode, setIsDarkMode] = useState(
    isBrowser ? localStorage.getItem("darkMode") === "true" : false
  );

  useEffect(() => {
    const root = window.document.documentElement;

    if (isDarkMode) {
      root.classList.add("dark");
    } else {
      root.classList.remove("dark");
    }
  }, [isDarkMode]);

  return (
    <ProviderContext.Provider value={{ isDarkMode, setIsDarkMode }}>
      {props.children}
    </ProviderContext.Provider>
  );
}
